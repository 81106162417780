.td {
    font-size: small;
}

.td2 {
    font-size: small;
    font-weight: bold;
}

.inscricao {
    font-size: small;
    min-width: 8.75rem;
}