.form {
    margin: 0 auto; 
   
}

.principal
{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

img {
    width: 100%;
    height: 100vh;
}

.titulo {
    margin-top: 1rem;
    text-align: center;
    /* color: #C0C0C0; */
    color: CornflowerBlue;
    font-size: 1.8rem;
    font-weight: bold;
    font-family:sans-serif;
}

.container {
  /*  width: 350px;
    height: 240px; */  
    margin-top: 2rem;
    height: 75vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;           

}

.box {
    margin-top: 4rem;
    position: relative;
    width: 28em;
    height: 26em;
   /*  background-color: whitesmoke;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156,156,156); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

p {
    font-size: 1.5rem;
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    color: #132350;
    text-decoration-line: none;
    
}

.inputs {
    border: 0.1px solid #ADD8E6;
    border-radius: 0.4rem;
    width: 23rem;
    font-size: 1rem;
    padding: 0.75rem;
    margin-top: 8px;
    background: ghostwhite;
}

.inputs:focus {
    border-color: royalblue;
    outline: none;
}

.btn {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    width: 23.2rem;
    height: 3rem;
    text-align: center;
    padding: 0.75rem;
    font-size: 1rem;   
    font-weight: bolder;
    border-radius: 8px;
    background-color: #132350;
}

.btn:hover {
    background-color: navy;
}

@media (max-width: 1000px)
{
    .principal
    {
        grid-template-columns: 1fr;
    }
    
    img {
        display: none;       
    }
}

@media (max-width: 600px) {

    .box {
        width: 22em;
        height: 15em;
    
    }

    p {
        font-weight: lighter;
    }

    .inputs {
        border: 0.5px solid #eee;
        border-radius: 0.25rem;
        width: 16rem;
        font-size: 0.75rem;
        padding: 0.5rem;
        }

    .btn {
        width: 16.05rem;
        padding: 0.5rem;
        font-size: 0.75rem;
    }
}
