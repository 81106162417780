.td {
    font-size: small;
}

.trBody {
    font-size: smaller;
}

.inscricao {
    font-size: small;
    min-width: 8.75rem;
}
