.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: whitesmoke;   
    position:fixed;
    bottom: 10px;
    top: 0px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.modalContainer {
    line-height: 1.125;
    width: 615px;
    height: 285px;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-top: -100px;
}

.modalContainer h3 {
    text-align: left;
    color: darkgray;
    font-style: italic;
}


.modalBody {
    display: grid;
    grid-template-columns: 1fr;
    
}

.modalBody label {
    font-weight: bold;
}

.modalFooter {
    display: flex;
    justify-content: center;
    align-items: center;
}

p {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    margin-top: 12px;
}

.btn {
    width: 150px;
    height: 40px;
    margin-top: 20px;
    margin-left: 12px;
    padding: 0.25em;
    text-align: center;
    border-radius: 5px;    
    justify-content: center;   
    font-size: medium;

}


@media (max-width: 670px) 
{ 
    .modalContainer {
        line-height: 1.05;
        width: 465px;
        height: 285px;
        border-radius: 10px;
        background-color: white;
        box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        margin-top: -80px;
    }

    .btn {
        margin-top: 3px;
    }
    
}

@media (max-width: 525px) 
{ 
    .modalContainer {
        line-height: 1.015;
        width: 425px;
        height: 325px;
        margin-top: -115px;
    }

    .modalBody {
        grid-template-columns: 1fr;
    }

    .btn {
        margin-top: 3px;
    }
}
