.botoes {
  position: relative;
}

.barra {    
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.td {
    font-size: small;
}

@media (max-width: 900px)
{
  .barra {
    margin-left: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }
}

@media (max-width: 500px)
{
  .barra {
    margin-left: 0.25rem;
    display: grid;
    grid-template-columns: 1fr;   
  }
}