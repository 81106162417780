.formulario {
    margin-top: 20px;
}

h2 {
    color: gray;
    font-weight: lighter;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;    
    font-size: 20px;
}

.container {
    display: grid;    
}

.grafico {
    margin: 0 auto;
    align-items: center; 
    width: 60vw;
    max-width: 700px;
}

.cardcontainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;   
}

.cards {
    margin-left: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;   
    gap: 1rem;   
}

.cardItem {   
    width: 325px;
}

@media (max-width: 1080px)
{
    .cardItem {
        width: 275px;
    }
}

@media (max-width: 900px) {
    .cards {
        margin-left: 0.5rem;
        grid-template-columns: 1fr;
    }

    .cardItem {
        width: 250px;
    }
}