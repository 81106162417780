.contratos {
    width: 87vw;
    margin: 0 auto;
    box-shadow: 1px 1px 5px rgb(163, 163, 163);
    border-radius: 8px;
}

.detailsHeader {
  background: ghostwhite;
  color: dimgray; 
  border: 0;
  box-shadow: 0 0 5px #C0C0C0;
}

.heading {
  text-align: center;
  font-size: 1.5rem;
}

.registros > tr > td {
    font-size: large;
    font-weight:normal;
    min-width: 8rem;
    color: dimgray;
}

.headerRow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    width: 5rem;
    text-align: center;  
    font-weight: bold;
    color: dodgerblue;
    border: 1px solid dodgerblue;
}

.btn:hover {
    background-color: dodgerblue;
}

.btn:focus {
    background-color: dodgerblue;
}