.main {
    list-style: none;
    display: flex;   
    margin-top: 0.5rem;
    margin-left: 1rem;
}

.registros {
    font-size: small;
    color: rgb(29, 28, 32);
}

.btnPage {
    margin-left: 1rem;
    height: 50%;
}