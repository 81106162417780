.form {
    margin-top: 15px;
    margin-left: 1.25rem;
    margin-right: 0.5rem;
    width: 96%;
    /* max-width: 1200px; */
    align-items: center;
}

.areaBtn {
    margin-top: 1.25rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1.25rem;
   
}

.btn {
    width: 240px;
    height: 3.10rem;
    text-align: center;  
    margin-right: 1.75rem;
    border-radius: 8px;
    font-weight: bold;
}

.btn2 {
    width: 180px;
    height: 3.45rem;
    text-align: center;  
    border-radius: 5px;
    font-weight: bold;
}

.registros > tr > td {
    font-size: large;
    font-weight:normal;
    color: dimgray;
}

.grafico {
    margin: 0 auto;   
    align-items: center; 
    width: 70vw;
    max-width: 800px;
}

.obs {
    color: dimgray;
    font-size: 0.9rem;
    margin-left: 3rem;
    margin-top: 1rem;
    position: absolute;
}

.tableHeader {
    background-color: 'dodgerblue'; 
    color: 'whitesmoke';
}

.form > div > table > tbody > tr > td {
    min-width: 104px;
}

@media (max-width: 1200px) {
    .form > div > table > tbody > tr > td {
        font-size: 0.9rem;
    }
}

@media (max-width: 800px) {
    .form {
        margin-left: 0.5rem;  
    }

    .form > div > table > tbody > tr > td {
        font-size: 0.8rem;
    }
}