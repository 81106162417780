.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.legendaLoad::after {
  content: '...';
  animation: dots 3s infinite;
  opacity: 0;
}

@keyframes dots {
  0% {
    content: '.  ';
    opacity: 0;
  }
  25% {
    content: '.  ';
    opacity: 1;
  }
  50% {
    content: '.. ';
    opacity: 1;
  }
  75% {
    content: '...';
    opacity: 1;
  }
  100% {
    content: '.  ';
    opacity: 0;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinnerContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}

.loadingSpinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
