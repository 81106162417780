.formulario {
    margin-top: 20px;
}

h2 {
    color: gray;
    font-weight: lighter;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;    
    font-size: 20px;
}

h3 {
    color: gray;
    font-weight: bolder;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;    
    font-size: 16px;
}

.container {
    display: grid;    
}

.container2 {
    display: flex;    
    align-items: center;
    justify-content: center;   
    width: 100%;   
}

.container3 {
    display: flex;    
    align-items: center;
    justify-content: center;   
    width: 100%;   
}

.grafico {
    margin: 0 auto;   
    align-items: center; 
    width: 60vw;
    max-width: 700px;
}

.grafico2 {
    display: grid;
    margin-left: 0.5rem;    
    grid-template-columns: 0.5fr 1.5fr 0.5fr 1.5fr;
    /* margin: 0 auto; */
    align-items: center; 
}

.grafico2Item {
    width: 400px;
}

.graficoItem3 {
    width: 525px;
}
.grafico3 {
    display: grid;
    margin-left: 0.5rem;    
    grid-template-columns: 0.5fr 1.5fr;
    align-items: center; 
}

.tabela {
    margin-top: 3rem;
    margin-left: 2rem;
    width: 700px;
}

@media (max-width: 1080px)
{
}

@media (max-width: 900px) {
}