.form {
    margin: 1rem auto;
    width: 96%;
    max-width: 1200px;
    align-items: center;
}

.areaBtn {
    margin-top: 1.25rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1.25rem;
   
}

.btn {
    width: 240px;
    height: 3.10rem;
    text-align: center;  
    margin-right: 1.75rem;
    border-radius: 8px;
    font-weight: bold;
}

.btn2 {
    width: 180px;
    height: 3.45rem;
    text-align: center;  
    border-radius: 5px;
    font-weight: bold;
}

@media (max-width: 680px) {
    .form {
        margin-left: 0.5rem;       
    }
}