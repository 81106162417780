.form {
    margin-top: 15px;
    margin-left: 1.25rem;
    margin-right: 0.5rem;    
    width: 96%;
    /* max-width: 1200px; */
    align-items: center;
}

.areaBtn {
    margin-top: 1.25rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1.75rem;
   
}

.btn {
    width: 240px;
    height: 3.10rem;
    text-align: center;  
    margin-right: 1.75rem;
    border-radius: 8px;
    font-weight: bold;
}

.btn2 {
    width: 180px;
    height: 3.45rem;
    text-align: center;  
    border-radius: 5px;
    font-weight: bold;
}

.card {
    margin-bottom: 1.5rem;
    margin-left: 3rem;
    width: 50%;
    height: 125px;
    align-items: center; 

}

.valor {
    color: dodgerblue;
    font-size: x-large;
}

.td1 {
    font-size: large;
    font-weight:normal;
    color: dimgray;
}

.td2 {
    color: dodgerblue;
    font-size: large;
}

.tabela2 {
    margin-left: 3rem;
    max-width: 925px;
}

.margemtab2 {
    margin-left: 3rem;
}

.td3 {
    font-size: normal;
    font-weight: normal;
    font-style: italic;
    color:grey;
}

.td4 {
    color: dodgerblue;
    font-size: small;
    font-weight: bold;
}

.td5 {
    color: darkgrey;
    font-size: small;
    font-weight: bold;
}

@media (max-width: 1025px) {
    .margemtab2 {
        margin-left: 1.5rem;
    }
    
    .tabela2 {
        margin-left: 1.5rem;
        max-width: 875px;
    }
    
}

@media (max-width: 950px) {
    .margemtab2 {
        margin-left: 0.75rem;
    }
    
    .tabela2 {
        margin-left: 0.75rem;
        max-width: 815px;
    }
    
}

@media (max-width: 875px) {
    .form {
        margin-left: 0.5rem;       
    }

    .card {
        width: 45%;
        height: 100px;
        margin-left: 1rem;
    }

    .valor {
        font-size: large;
    }

    .margemtab2 {
        margin-left: 0.15rem;
    }
    
    .tabela2 {
        margin-left: 0.15rem;
        max-width: 715px;
    }

}