.tableHeader {
    background-color: dodgerblue; 
    color: whitesmoke;
    font-size: large;
    font-weight: bold;
}

.tableHeader > th {
    padding: 0.8rem;
}

.registros > tr > td {
    font-size: large;
    font-weight: normal;
    color: dimgray;
}

.expandButton {
    border-radius: 8px;
    border: 0;
    background-color: transparent;
}

.expandButton:focus{
    outline: none;
    outline-offset: 0px;
}
