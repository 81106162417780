.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: whitesmoke;
    position:fixed;
    bottom: 10px;
    top: 0px;
    display:flex;
    align-items: center;
    justify-content: center;
}

.modalContainer {
    line-height: 1.125;
    width: 720px;
    height: 540px;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-top: -50px;
}

.modalContainer h4 {
    font-size: large;
    text-align: left;
    color: darkgray;
    font-style: italic;
}


.modalBody {
    display: grid;
    grid-template-columns: 0.30fr 0.70fr;
    
}

.modalBody p {
    text-align: left;
    text-decoration-line: none;
    font-weight: bold;
    font-size: 1rem;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
}

.modalBody label {
    font-weight: normal;
}

.modalFooter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    width: 150px;
    height: 40px;
    margin-top: 10px;
    margin-left: 12px;
    padding: 0.25em;
    text-align: center;
    border-radius: 5px;    
    justify-content: center;   
    font-size: medium;

}


@media (max-width: 725px) 
{ 
    .modalContainer {
        line-height: 1.05;
        width: 480px;
        height: 555px;
        border-radius: 10px;
        background-color: white;
        box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        margin-top: -60px;
    }

    .modalBody {
        grid-template-columns: 0.35fr 0.65fr;

    }

    .btn {
        margin-top: 8px;
    }
    
}

@media (max-width: 525px) 
{ 
    .modalContainer {
        line-height: 0.95;
        width: 500px;
        height: 625px;
        margin-top: -10px;
    }

    .modalBody {
        grid-template-columns: 1fr;
    }

    .btn {
        margin-top: 5px;
    }
}
