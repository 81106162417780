.dashboardContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 50rem;
    max-height: 100vh;
}

.chartHeader {
    text-align: center;

    display: flex; 
    justify-content: center; 
    align-items: center;
}

.component {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    max-width: 80%; 
    max-height: 80vh; 
    width: auto; 
    height: auto; 

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

.active {
    opacity: 1;
    visibility: visible;
}

.captionContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
}

.captionLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.captionColorSquare {
    width: 15px; 
    height: 15px; 
    box-shadow: 1px 1px 3px grey;
}


.btn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: flex-end;

    margin-left: 0.7rem;

    border-radius: 8px;
    border: 0;

    font-weight: bold;
    color: dimgray;

    background-color: white;
}

.btn:hover {
    color: black;
    background-color: whitesmoke;
}

.btn:focus {
    background-color: whitesmoke;
}