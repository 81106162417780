.cardContainer {
  max-width: 96vw;
  max-height: 38vh;
  overflow: auto;

  margin: 0.8rem auto;
  padding: 1rem;
  border: 0;
  box-shadow: 1px 1px 5px #d3d2d2;
}

.cardContainer::-webkit-scrollbar {
  width: 15px;
}

.cardContainer::-webkit-scrollbar-track {
  background: transparent;
  padding: 2px;
}

.cardContainer::-webkit-scrollbar-thumb {
  background-color: #d3d2d2;
  border-radius: 20px; 
  border: 3px solid white;
}