.nav_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
}

.menu_items {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* min-width: 600px; */
    @media screen and (max-width: 600px) {
        display: block;
    }
}

.logout_container {
    display: flex;
    align-items: center;
    color: darkgray;
}